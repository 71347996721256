import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import {
  // eslint-disable-next-line camelcase
  required, numeric, min_value, max_value, one_of, email,
} from '@vee-validate/rules';
import de from '@vee-validate/i18n/dist/locale/de.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';

defineRule('required', required);
defineRule('numeric', numeric);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('one_of', one_of);
defineRule('email', email);
defineRule('step', (value, [step]) => Math.abs(value % step) === 0);
defineRule('is_object', (value) => typeof value === 'object');

configure({
  generateMessage: localize({
    de,
    nl,
  }),
});
